<template>
    <!-- eslint-disable vue/no-v-html -->
    <div class="product-out-wrapper">
        <Modal
            ref="out"
            class="product-out__popup"
            aria-labelledby="Powiadom mnie o dostępności tego produktu"
            theme="dots"
            @enter="init"
            @leave="resetForm"
        >
            <template #content>
                <div class="confirmation">
                    <div
                        v-if="isPharmacySet && formSuccess === null"
                        class="product-out_pharmacy_set"
                    >
                        <form
                            id="product-out"
                            ref="form"
                            class="product-out__form"
                            autocomplete="off"
                            @submit.prevent="notifyFormSubmit"
                        >
                            <span class="h4">
                                Powiadom mnie o dostępności tego produktu
                            </span>
                            <input
                                class="g-recaptcha-response"
                                name="g-recaptcha-response"
                                type="hidden"
                                value=""
                            />
                            <input
                                type="hidden"
                                name="action"
                                value="CustomerProductOutForm"
                            />
                            <input
                                ref="email"
                                type="email"
                                name="email"
                                class="form-control"
                                placeholder="Wpisz swój adres e-mail"
                                required
                            />
                            <div
                                id="advice-required-entry-email"
                                class="validation-advice hidden"
                                role="alert"
                            >
                                To pole jest wymagane.
                            </div>

                            <div class="availability-agreement">
                                <div
                                    v-if="cmsBlocks"
                                    class="availability-agreement__text"
                                    v-html="cmsBlocks"
                                />
                                <details v-if="cmsBlocksTooltips">
                                    <summary>
                                        <span>Rozwiń</span>
                                        <span>Zwiń</span>
                                    </summary>
                                    <div v-html="cmsBlocksTooltips" />
                                </details>
                            </div>

                            <button id="product-out-save" type="submit">
                                <span>Powiadom mnie</span>
                            </button>

                            <small class="g-recaptcha-legal" />
                        </form>
                    </div>
                    <div
                        v-if="!isPharmacySet && !formSuccess"
                        class="product-out_pharmacy_not_set"
                    >
                        <p>
                            <strong>
                                Aby otrzymać powiadomienie należy najpierw
                                wybrać aptekę.
                            </strong>
                        </p>
                        <a
                            class="header-main__button select-pharmacy-pin js-select-pharmacy"
                            href="#"
                            @click.prevent="choosePharmacy"
                        >
                            <img
                                class="product-out__icon"
                                :src="getSkinUrl('icons/pin.svg')"
                                alt=""
                            />
                            <span
                                class="label overflow select-pharmacy-text"
                                style="opacity: 1"
                            >
                                Wybierz aptekę
                            </span>
                        </a>
                    </div>
                    <div v-if="formSuccess === true" class="product-out__info">
                        <span class="h2">Powiadomienie zapisane</span>
                        <p>
                            Gdy tylko produkt będzie dostępny, powiadomimy Cię
                            e-mailem
                        </p>
                        <button
                            type="button"
                            class="button btn btn--bg"
                            @click.prevent="$refs.out.CloseModal()"
                        >
                            <span>Kontynuuj</span>
                        </button>
                    </div>
                    <div v-if="formSuccess === false" class="product-out__info">
                        <span class="h2">Wystąpił problem</span>
                        <p>Spróbuj ponownie później.</p>
                        <button
                            type="button"
                            class="button btn btn--bg"
                            @click.prevent="$refs.out.CloseModal()"
                        >
                            <span>Kontynuuj</span>
                        </button>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '../partials/Modal.vue'
import axios from 'axios'
import getAgreements from '@js/utils/getAgreements.js'
import initRecaptcha from '@js/app/recaptcha.js'
import getCmsBlocks from '@js/utils/getCmsBlocks.js'

export default {
    scopeName: 'productout',
    components: {
        Modal
    },
    data() {
        return {
            formSuccess: null,
            agreement: '',
            productAttribute: null,
            isPharmacySet: false,
            cmsBlocks: null,
            cmsBlocksTooltips: null
        }
    },
    mounted() {
        Array.from(document.querySelectorAll('.notify-link')).forEach(
            (notifyLink) => {
                notifyLink.addEventListener(
                    'click',
                    (event) => {
                        event.preventDefault()
                        this.productAttribute = event.currentTarget.getAttribute(
                            'data-product-id'
                        )
                        this.$refs.out.OpenModal()
                    },
                    false
                )
            }
        )
    },
    methods: {
        OpenProductOutModal(id) {
            this.productAttribute = id
            this.$refs.out.OpenModal()
        },
        init() {
            this.isPharmacySet = Cookies.get('userPharmacy')
            if (!this.agreement) {
                this.getAgreement()
            }
            if (!this.cmsBlocks) {
                this.getBlocks()
            }
            if (this.isPharmacySet && GOOGLE_RECAPTCHA_ENABLED) {
                this.$nextTick(() =>
                    initRecaptcha(this.$refs.form['g-recaptcha-response'])
                )
            }
        },
        resetForm() {
            this.$nextTick(() => {
                Object.assign(this.$data, this.$options.data.apply(this))
            })
        },
        getAgreement() {
            getAgreements(['availability-notify']).then((response) => {
                if (response?.[0]?.description) {
                    this.agreement = response?.[0]?.description + '<em>*</em>'
                }
            })
        },
        choosePharmacy() {
            this.$refs.out.CloseModal()
            window.$apteline.pharmacy.SelectPharmacy()
        },
        notifyFormSubmit() {
            const params = new URLSearchParams()
            params.append(
                'product_id',
                this.productAttribute
                    ? this.productAttribute
                    : document.getElementById('product_id').value
            )
            params.append(
                'form_key',
                document.querySelector('input[name="form_key"]').value
            )
            params.append('email', this.$refs.form.email.value)
            if (this.$refs.form['g-recaptcha-response']) {
                params.append(
                    'g-recaptcha-response',
                    this.$refs.form['g-recaptcha-response'].value
                )
            }
            if (this.$refs.form['action']) {
                params.append('action', this.$refs.form['action'].value)
            }
            axios
                .post(BASE_URL + 'base/ajax/stockAlert', params)
                .then((response) => {
                    if (response.data.success) {
                        this.formSuccess = true
                        if (window?.GoogleAnalyticsUniversal) {
                            window.GoogleAnalyticsUniversal.prototype.notifyUnavailable()
                        }
                    } else {
                        this.formSuccess = false
                    }
                })
        },

        getBlocks() {
            getCmsBlocks(
                'popup_notification_of_product_availability,popup_notification_of_product_availability_tooltip'
            ).then((blocks) => {
                if (blocks.popup_notification_of_product_availability) {
                    this.cmsBlocks =
                        blocks.popup_notification_of_product_availability
                }
                if (blocks.popup_notification_of_product_availability_tooltip) {
                    this.cmsBlocksTooltips =
                        blocks.popup_notification_of_product_availability_tooltip
                }
            })
        }
    }
}
</script>

<style lang="scss">
.product-out {
    text-align: center;

    @media #{$media-xs} {
        max-width: 100%;
    }

    & + .product-shop__cart {
        margin-top: 40px;
    }

    &__header {
        margin-bottom: 20px;
        text-align: left;
        font-size: 30px;
        font-weight: 400;
        line-height: 24px;
        color: palette(red);

        @media #{$media-to-sm} {
            font-size: 20px;
        }
    }

    &__icon {
        width: 30px;
    }

    &__form {
        position: relative;

        .g-recaptcha-legal {
            position: absolute;
            top: 100%;
            width: 100%;
        }
    }

    &__popup {
        em {
            color: palette(red);
        }

        .confirmation {
            text-align: center;

            input {
                text-align: center;
            }

            .h4 {
                display: block;
                margin-bottom: 35px;
                font-size: 20px;
                line-height: 1.3;
                font-weight: $font-weight-bold;

                @media #{$media-xs} {
                    font-size: 17px;
                }
            }

            .availability-agreement {
                margin-top: 30px;
                margin-bottom: 5px;

                p {
                    line-height: 1.2;
                    font-size: 14px;
                    text-align: left;
                }

                a {
                    text-decoration: underline;
                }

                details {
                    text-align: left;

                    summary {
                        text-transform: uppercase;
                        cursor: pointer;

                        span {
                            &:first-child {
                                display: block;
                            }
                            &:last-child {
                                display: none;
                            }
                        }
                    }

                    &[open] {
                        summary span {
                            &:first-child {
                                display: none;
                            }
                            &:last-child {
                                display: block;
                            }
                        }
                    }
                }
            }

            button {
                min-width: 229px;
            }
        }

        .btn {
            margin-top: 20px;
        }

        .clause {
            text-align: left;
            margin-top: 40px;
            padding-top: 25px;
            border-top: 1px solid palette(gray, lighter);
        }

        .input-group {
            margin-bottom: 15px;
            text-align: left;

            &-btn {
                vertical-align: top;
            }
        }

        .modal-content {
            padding-top: 75px;
        }

        .modal-body {
            max-width: 500px;
            margin: 0 auto;
        }
    }

    &__item {
        position: relative;
        width: 265px;
        margin: 35px auto 30px auto;
        text-align: left;

        @media #{$media-xs} {
            width: auto;
            margin: 0 auto;
        }

        .badge-label--blue {
            position: absolute;
            left: -10px;
            top: 10px;
            z-index: 1;
        }
    }

    &__form {
        .validation-advice {
            text-align: left;
        }

        .checkbox-custom {
            top: 20px;
            left: 10px;
            text-indent: unset;
            width: 1px;
            height: 1px;
            opacity: 0;
        }
    }
}
</style>
