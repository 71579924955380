import { loadScript } from '../utils/utils.js'

/**
 * This function is listening for every form submit.
 * If there is "g-recaptcha-response" input, we stop event propagation (to cancel any submit event attached to form),
 * execute reCAPTCHA API to fill proper input, and then we dispatch submit event manually.
 * Google reCaptcha is initialised on form interaction.
 * @param {Array|HTMLElement} grecaptchaInput - input element(s) for Google reCaptcha response
 */
export function initRecaptcha(grecaptchaInput) {
    if (!window.reCaptchaInitialised) {
        // hide default Google reCaptcha badge
        const HIDE_BADGE_CSS = document.createElement('style')
        HIDE_BADGE_CSS.innerText = `
          .grecaptcha-badge { display: none; }
          .g-recaptcha-legal { display: block; margin-top: 10px; }
        `
        document.head.appendChild(HIDE_BADGE_CSS)
    }
    // init Google reCaptcha on form interaction
    if (Array.isArray(grecaptchaInput)) {
        grecaptchaInput.forEach((input) => attachEvents(input))
    } else {
        attachEvents(grecaptchaInput)
    }
}

const attachEvents = (input) => {
    const form = input.form
    form.addEventListener('click', () => startOnInteraction(form), false)
    form.addEventListener('focusin', () => startOnInteraction(form), false)
}

const startOnInteraction = (form) => {
    if (form.dataset?.recaptcha) {
        return
    }
    form.dataset.recaptcha = 'true'

    addLegalNotice(form)

    if (!window.reCaptchaInitialised) {
        window.registerReCaptchaListener = () => {
            window.reCaptchaInitialised = true
            window.addEventListener('submit', submitListener, true)
        }

        loadScript(
            `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_KEY}&hl=pl&onload=registerReCaptchaListener`,
            {
                async: true,
                defer: true
            }
        )
    }
}

const submitListener = (event) => {
    const form = event.target
    const recaptchaInput = form['g-recaptcha-response']

    if (recaptchaInput) {
        event.preventDefault()
    }

    // if recaptcha is not enabled for this form or is already filled - don't do anything
    if (recaptchaInput && !recaptchaInput.value) {
        event.stopPropagation()

        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(GOOGLE_RECAPTCHA_KEY, {
                    action: form?.action?.value || 'form'
                })
                .then((token) => {
                    recaptchaInput.value = token
                    form.dispatchEvent(event)
                })
                .catch((error) => {
                    console.error(error)
                    recaptchaInput.value = 'error'
                    form.dispatchEvent(event)
                })
        })
    }
}

/**
 * Adds legal notice to form
 * @param {HTMLFormElement} form
 */
const addLegalNotice = (form) => {
    const LEGAL_NOTICE = `Strona korzysta z zabezpieczenia reCAPTCHA.
        Obowiązują <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">polityka prywatności</a>&nbsp;i
        <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">warunki</a> Google.`
    const noticePlaceholder = form.querySelector('.g-recaptcha-legal')

    /**
     * You can choose where notice should be placed in form, by inserting
     * <small class="g-recaptcha-legal" style="display: none;"></small>
     * somewhere in the form.
     */
    if (noticePlaceholder) {
        noticePlaceholder.innerHTML = LEGAL_NOTICE
        noticePlaceholder.style.display = 'block'
    } else {
        const notice = document.createElement('small')
        notice.classList.add('g-recaptcha-legal')
        notice.innerHTML = LEGAL_NOTICE
        form.appendChild(notice)
    }
}

export default initRecaptcha
