import axios from 'axios'

/**
 * @param {array} agreements
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAgreements = async (agreements) => {
    return await axios
        .get(
            BASE_URL +
                `agreements/ajax/agreement?identifier=${agreements.join(',')}`
        )
        .then(function (response) {
            return response.data
        })
}

export default getAgreements
